table {
    width: 100%;
    border-collapse: collapse;
    font-size: 14px; /* Ajustamos el tamaño de fuente general */
}

table th, table td {
    text-align: center;
    padding: 5px; /* Espaciado dentro de las celdas */
    border: 1px solid #ddd;
}

table th {
    background-color: #f2f2f2;
    font-weight: bold;
    font-size: 14px; /* Ajuste del tamaño de fuente para los encabezados */
}

table tr:nth-child(even) {
    background-color: #f9f9f9;
}

table tr:hover {
    background-color: #f1f1f1;
}

table img {
    display: block;
    margin: 0 auto;
    height: 30px; /* Ajusta el tamaño de la imagen a 30px */
    width: 30px;
    object-fit: contain;
}

/* Ajustar el ancho de las columnas "Teléfono", "Movil", "Producto1" y "Producto2" */
table th:nth-child(4), table td:nth-child(4), /* Teléfono */
table th:nth-child(8), table td:nth-child(8), /* Producto1 */
table th:nth-child(9), table td:nth-child(9), /* Producto2 */
table th:nth-child(10), table td:nth-child(10) /* Movil */ {
    width: 100px; /* Ajustamos el ancho a 100px */
}

/* La columna "Dirección" se ajustará automáticamente */
table th:nth-child(7), table td:nth-child(7) /* Dirección */ {
    width: auto;
}

/* Estilos para todos los inputs dentro de la tabla */
td input[type="text"], td select {
    width: 100%;               /* El ancho será el 100% de la columna */
    padding: 8px;              /* Espaciado interno */
    font-size: 14px;           /* Tamaño del texto */
    border: none;              /* Eliminar bordes */
    box-sizing: border-box;     /* Asegurar que el padding no aumente el ancho total */
}

td input[type="text"]:focus, td select:focus {
    outline: none;             /* Eliminar el borde de foco por defecto */
    border: 1px solid #ccc;    /* Agregar un borde sutil al enfocar */
}

/* Eliminar solo el borde de la celda que contiene los botones */
table td:last-child {
    border: none;              /* Eliminar el borde de la última celda */
    padding: 5px;              /* Reducir el padding para que los botones tengan más espacio */
}

/* Mantener el borde derecho de la columna "Tiempo" */
table td:nth-last-child(2) {
    border-right: 1px solid #ddd; /* Mantiene el borde derecho de la penúltima columna (Tiempo) */
}

/* Centrar y eliminar el fondo del último <td> donde están los botones */
table td:last-child img {
    display: inline-block;      /* Asegura que los botones se muestren en línea */
    margin: 0 5px;             /* Añade un pequeño margen entre los botones */
}

/* Ajuste adicional para inputs de texto */
input[type="text"] {
    font-size: 14px; /* Tamaño de texto ajustado para los inputs */
}

/* Estilos para el título */
h1 {
    font-size: 20px; /* Ajuste del tamaño del título */
}

/* Tamaño de fuente reducido para las celdas de la tabla */
table td, table th {
    font-size: 12px;
}
.pedidos-container {
    margin-left: 20px; /* Aplica el margen izquierdo solo a esta vista */
    padding: 0px; /* Espaciado interno opcional */
}


