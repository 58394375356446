/* src/styles/Sidebar.css */
.sidebar {
    width: 500px; /* Ajusta este valor al ancho deseado */
    background-color: #333;
    color: white;
    padding: 20px;
    height: 100vh;
    position: fixed;
    transition: width 0.3s ease; /* Transición suave */
    text-align: center; /* Centra el texto */
}

.sidebar h2 {
    margin-bottom: 20px;
}

.sidebar ul {
    list-style-type: none;
    padding: 0;
}

.sidebar ul li {
    margin: 20px 0;
}

.sidebar ul li a {
    color: white;
    text-decoration: none;
    display: inline-block; /* Asegura que los enlaces se centren */
    width: 100%; /* Opcional: extiende los enlaces */
}

.sidebar ul li a.active {
    font-weight: bold;
}

.logout-btn {
    background-color: #e74c3c;
    border: none;
    color: white;
    padding: 10px 20px;
    cursor: pointer;
    margin-top: 20px;
    display: inline-block; /* Permite el centrado del botón */
}
