/* Layout principal */
.layout {
    display: flex;
    height: 100vh;
    width: 100%;
}

/* Sidebar */
.sidebar {
    width: 450px; /* Este valor define el ancho del sidebar */
    background-color: #333;
    color: white;
    padding: 20px;
    position: fixed;
    height: 100%;
    top: 0;
    left: 0;
    overflow-y: auto;
    z-index: 100;
    transition: width 0.3s ease; /* Agregado para transición suave */
}

/* Si el sidebar está colapsado */
.layout.collapsed .sidebar {
    width: 100px; /* Ancho del sidebar cuando está colapsado */
}

.main-content {
    margin-left: 300px; /* Este valor debe coincidir con el ancho del sidebar */
    padding: 20px;
    width: calc(100% - 250px); /* Ajusta este valor en función del ancho del sidebar */
    height: 100%;
    overflow-y: auto;
    transition: margin-left 0.3s ease; /* Agregado para una transición suave */
}

.layout.collapsed .main-content {
    margin-left: 140px; /* Ajustar cuando el sidebar está colapsado */
    width: calc(100% - 80px); /* Ajustar el ancho del contenido */
}

/* Estilos para el botón de colapsar/expandir */
.toggle-btn {
    margin-bottom: 20px;
    background-color: #e74c3c;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
}
/* Elimina padding en vistas de mapas */
.main-content.no-padding {
    padding: 0; /* Eliminar padding */
    height: 100vh; /* Asegura que ocupe toda la pantalla */
    overflow: hidden; /* Elimina scroll en vistas de mapas */
}
