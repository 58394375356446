.gestion-usuarios-container {
    max-width: 800px;
    margin: 20px auto;
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.gestion-usuarios-tabla {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    background-color: white;
    border-radius: 5px;
    overflow: hidden;
}

.gestion-usuarios-tabla th,
.gestion-usuarios-tabla td {
    padding: 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.gestion-usuarios-tabla th {
    background-color: #4CAF50;
    color: white;
}

.gestion-usuarios-tabla tr:hover {
    background-color: #f1f1f1;
}

.gestion-usuarios-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 20px;
}

.gestion-usuarios-form input,
.gestion-usuarios-form select {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 5px;
    outline: none;
    width: 100%;
}

.gestion-usuarios-form input:focus,
.gestion-usuarios-form select:focus {
    border-color: #4CAF50;
    box-shadow: 0 0 5px rgba(76, 175, 80, 0.5);
}

.password-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.checkbox-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.checkbox-container label {
    display: flex;
    align-items: center;
    gap: 5px;
}

button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 15px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #45a049;
}

button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}
