/* src/styles/Moviles.css */

.moviles-container {
    padding: 20px;
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Alinear todo a la izquierda */
  }
  
  .moviles-form {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin-bottom: 20px;
    width: 100%;
  }
  
  .moviles-form div {
    margin-bottom: 10px;
  }
  
  .moviles-form label {
    font-weight: bold;
    margin-right: 10px;
  }
  
  .moviles-form input {
    padding: 5px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .btn-agregar {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .btn-agregar:hover {
    background-color: #45a049;
  }
  
  .contactos-table {
    margin-top: 20px;
    border-collapse: collapse;
    text-align: center; /* Centrar texto dentro de la tabla */
  }
  
  .contactos-table th,
  .contactos-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center; /* Centrar texto horizontalmente */
    vertical-align: middle; /* Centrar texto verticalmente */
  }
  
  .contactos-table th {
    background-color: #f2f2f2;
    font-weight: bold;
    white-space: nowrap; /* Evitar que los textos de los headers se partan */
  }
  
  .contactos-table td {
    white-space: nowrap; /* Evitar que los textos en las celdas se partan */
  }
  
  .contactos-table .actions-cell {
    width: 150px; /* Ancho suficiente para los botones */
    display: flex;
    justify-content: center; /* Centrar botones horizontalmente */
    gap: 10px; /* Separación entre botones */
  }
  
  .btn-editar,
  .btn-borrar {
    padding: 5px 10px;
    border: none;
    border-radius: 5px; /* Bordes redondeados */
    cursor: pointer;
  }
  
  .btn-editar {
    background-color: #FFC107;
    color: black;
  }
  
  .btn-editar:hover {
    background-color: #e0a800;
  }
  
  .btn-borrar {
    background-color: #f44336;
    color: white;
  }
  
  .btn-borrar:hover {
    background-color: #d32f2f;
  }
  