.pedidos-historicos-container {
    /* Espaciado y fuente */
    padding: 20px;
    font-family: Arial, sans-serif;

    /* Ajustar la altura para evitar espacio adicional */
    max-height: calc(100vh - 40px); /* Usa casi toda la altura disponible */
    overflow-y: auto;              /* Forzar barra de desplazamiento vertical */
    margin: 0;                     /* Sin margen extra */
    box-sizing: border-box;        /* Para evitar conflictos de padding */
}

.filtros-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;                     /* Espaciado entre elementos */
    margin-bottom: 20px;           /* Espaciado inferior del contenedor */
}

.filtros-container input,
.filtros-container select {
    padding: 10px;
    font-size: 14px;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 200px;
}

.pedidos-historicos-tabla {
    width: 100%;
    border-collapse: collapse;    /* Combinar bordes de celdas */
    margin: 0;                    /* Sin márgenes adicionales */
}

.pedidos-historicos-tabla th,
.pedidos-historicos-tabla td {
    padding: 10px;
    border: 1px solid #ccc;
    text-align: left;
}

.pedidos-historicos-tabla th {
    background-color: #f4f4f4;    /* Fondo gris claro */
}

.pedidos-historicos-tabla tr:nth-child(even) {
    background-color: #f9f9f9;    /* Fondo gris más claro para filas pares */
}

/* Ajustes globales para evitar márgenes y padding en general */
body, html {
    margin: 0;
    padding: 0;
    height: 100%;
}

/* Mantén la clase .sidebar sin modificaciones */
.sidebar {
    /* Aquí no realizamos ningún cambio */
    /* Tus estilos existentes para .sidebar permanecen intactos */
}
